import './polyfills';
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import history from "./history";

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};
console.log(config);
console.log(config.clientId);

createRoot(document.getElementById("root"))
.render(
  <Auth0Provider
    domain={config.domain}
    clientId={config.clientId}
    authorizationParams={{
      audience: config.audience,
      redirect_uri: window.location.origin
    }}


    onRedirectCallback={onRedirectCallback}
  >
    <App />
    </Auth0Provider>
);

serviceWorker.unregister();